/* eslint-disable @typescript-eslint/no-explicit-any */
import { OrganizationContext, PRODUCT_PLANS } from '@diagrid/cloud-ui-shared';
import { isNil } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UsersRolesContext } from 'src/contexts/UsersRolesContext';

declare global {
  interface Window {
    hsConversationsOnReady?: any;
    HubSpotConversations?: any;
    hsConversationsSettings: any;
    _hsq: any;
    hbspt: any;
  }
}

// HUBSPOT CHAT BOT INTEGRATION
// eslint-disable-next-line vars-on-top, no-var, no-unused-vars, no-multi-assign, @typescript-eslint/naming-convention
var _hsq = (window._hsq = window._hsq || []);

const {
  mcp: { free },
} = PRODUCT_PLANS;

export const useHubspotChat = () => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hsConvoInited, setHsConvoInited] = useState(false);
  const { diagridAccount } = useContext(UsersRolesContext);
  const { planName, loading: loadingOrg } = useContext(OrganizationContext);

  const isFreePlan = planName === free;

  const currentUser = useMemo(() => diagridAccount ?? {}, [diagridAccount]);

  useEffect(() => {
    // Add event listener.
    if (planName !== null && !loadingOrg) {
      if (window.HubSpotConversations) {
        setHasLoaded(true);
      } else {
        window.hsConversationsOnReady = [
          () => {
            setHasLoaded(true);
          },
        ];
      }
    }
  }, [loadingOrg, planName]);

  useEffect(() => {
    if (planName !== null && !loadingOrg && window.HubSpotConversations && !hsConvoInited) {
      if (isNil(currentUser?.hsToken)) {
        // somethings up with the hubspot user id api.
        // we should still load the widget, but not identify the user
        if (!isFreePlan) {
          window.HubSpotConversations?.widget?.load();
        }
      } else {
        // if we dont identify the user, track the page view and clear/reset the widget the contact will not be created in hubspot and the new chat will show unknown user
        // eslint-disable-next-line no-undef
        _hsq.push([
          'identify',
          {
            email: currentUser.email,
            name: currentUser.name,
            nickname: currentUser.nickname,
          },
        ]);
        // Track the page view for the new page
        // eslint-disable-next-line no-undef
        _hsq.push(['trackPageView']);

        window.hsConversationsSettings = {
          identificationEmail: currentUser.email,
          identificationToken: currentUser.hsToken,
          ...window.hsConversationsSettings,
        };

        if (!isFreePlan) {
          try {
            const status = window.HubSpotConversations.widget.status();

            if (status.loaded) {
              window.HubSpotConversations.clear({ resetWidget: true });
            } else {
              window.HubSpotConversations.widget.load();
            }
            setHsConvoInited(true);
          } catch (e) {
            console.warn(e);
          }
        }
      }
    }
  }, [currentUser.email, currentUser.hsToken, currentUser.name, currentUser.nickname, hsConvoInited, isFreePlan, loadingOrg, planName]);

  const openHandler = useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.open();
    }
  }, [hasLoaded]);

  const closeHandler = useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.close();
    }
  }, [hasLoaded]);

  return {
    hasLoaded,
    openHandler,
    closeHandler,
  };
};
