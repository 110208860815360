import { ChevronRightSharp, ExpandMoreSharp } from '@mui/icons-material';
import { Box, Link, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography, alpha, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { ICON, NAVBAR } from 'src/config';
import { NavConfig } from 'src/types/Navigation';
import { isExternalLink } from '..';
import RoleBasedGuard from '../../../guards/RoleBasedGuard';
import { useLocales } from '../../../hooks/useLocales';

type NavItemRootProps = {
  active: boolean;
  open?: boolean;
  isCollapse: boolean;
  onOpen?: () => void;
  item: NavConfig;
};
export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }: NavItemRootProps) {
  const { translate } = useLocales();
  const theme = useTheme();

  const { title, path, icon, info, children, disabled, caption, roles } = item;

  const renderContent = (
    <>
      {icon && <ListItemIconWrap>{icon}</ListItemIconWrap>}
      <ListItemText
        disableTypography
        primary={translate(title)}
        secondary={
          <Tooltip title={translate(caption) || ''} arrow>
            <Typography noWrap variant="caption" component="div" sx={{ textTransform: 'initial', color: 'text.secondary' }}>
              {translate(caption)}
            </Typography>
          </Tooltip>
        }
        sx={{
          whiteSpace: 'nowrap',
          textWrap: 'balance',
          transition: theme.transitions.create(['width', 'opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
          ...(isCollapse && {
            width: 0,
            opacity: 0,
          }),
        }}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemWrap onClick={onOpen} activeRoot={active} disabled={disabled} roles={roles}>
        {renderContent}
      </ListItemWrap>
    );
  }

  return isExternalLink(path) ? (
    <ListItemWrap component={Link} href={path} target="_blank" rel="noopener" disabled={disabled} roles={roles}>
      {renderContent}
    </ListItemWrap>
  ) : (
    <ListItemWrap component={RouterLink} to={path} activeRoot={active} disabled={disabled} roles={roles}>
      {renderContent}
    </ListItemWrap>
  );
}

type NavItemSubProps = {
  active: boolean;
  open?: boolean;
  onOpen?: () => void;
  item: NavConfig;
};

export function NavItemSub({ item, open = false, active = false, onOpen }: NavItemSubProps) {
  const { translate } = useLocales();

  const { title, path, info, children, disabled, caption, roles, icon: subItemIcon } = item;

  const renderContent = (
    <>
      {subItemIcon ? <ListItemIconWrap>{subItemIcon}</ListItemIconWrap> : <DotIcon active={active} />}
      <ListItemText
        disableTypography
        primary={translate(title)}
        secondary={
          <Tooltip title={translate(caption) || ''} arrow>
            <Typography noWrap variant="caption" component="div" sx={{ textTransform: 'initial', color: 'text.secondary' }}>
              {translate(caption)}
            </Typography>
          </Tooltip>
        }
      />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemWrap onClick={onOpen} activeSub={active} subItem disabled={disabled} roles={roles}>
        {renderContent}
      </ListItemWrap>
    );
  }

  return isExternalLink(path) ? (
    <ListItemWrap component={Link} href={path} target="_blank" rel="noopener" subItem disabled={disabled} roles={roles}>
      {renderContent}
    </ListItemWrap>
  ) : (
    <ListItemWrap component={RouterLink} to={path} activeSub={active} subItem disabled={disabled} roles={roles}>
      {renderContent}
    </ListItemWrap>
  );
}
type ListItemWrapProps = {
  roles: string[];
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
  component?: React.ElementType;
  disabled?: boolean;
  to?: string;
  href?: string;
  target?: string;
  rel?: string;
  onClick?: () => void;
};
function ListItemWrap({ roles, activeRoot, activeSub, subItem, children, ...props }: PropsWithChildren<ListItemWrapProps>) {
  const theme = useTheme();
  return (
    <RoleBasedGuard roles={roles}>
      <ListItemButton
        {...props}
        sx={{
          ...theme.typography.body2,
          position: 'relative',
          height: `${NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT}px`,
          textTransform: 'capitalize',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1.5),
          marginBottom: theme.spacing(0.5),
          color: theme.palette.text.secondary,
          borderRadius: theme.shape.borderRadius,
          // activeRoot
          ...(activeRoot && {
            ...theme.typography.subtitle2,
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            // not ideal but when menu is collapsed padding gets a little wonky so we need to adjust
            marginRight: '-12px',
          }),
          // activeSub
          ...(activeSub && {
            ...theme.typography.subtitle2,
            color: theme.palette.text.primary,
          }),
          // subItem
          ...(subItem && {
            height: `${NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT}px`,
          }),
        }}
      >
        {children}
      </ListItemButton>
    </RoleBasedGuard>
  );
}

export function DotIcon({ active }: { active: boolean }) {
  return (
    <ListItemIconWrap>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconWrap>
  );
}

function ListItemIconWrap({ children }: PropsWithChildren<unknown>) {
  return (
    <ListItemIcon
      sx={{
        width: `${ICON.NAVBAR_ITEM}px`,
        height: `${ICON.NAVBAR_ITEM}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': { width: '100%', height: '100%' },
      }}
    >
      {children}
    </ListItemIcon>
  );
}

export function ArrowIcon({ open }: { open: boolean }) {
  const iconProps = { width: 16, height: 16, ml: 1 };
  return open ? <ExpandMoreSharp sx={iconProps} /> : <ChevronRightSharp sx={iconProps} />;
}
