import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { deserialise, serialise } from 'kitsu-core';
import { ErrorResponseType } from '../types';
import { Normalization, normalizeResource } from '../utils';

type HandlePatchRequestOptions = {
  jsonApiClient: AxiosInstance;
  url: string;
  params?: Record<string, unknown>;
  axiosOptions?: Partial<AxiosRequestConfig>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
  resourceType: string;
  resourceId: string;
  normalization?: Normalization;
  method: 'POST' | 'PUT' | 'PATCH';
};

export async function handlepatchPatchPutPost({
  jsonApiClient,
  url,
  params,
  axiosOptions = {},
  data,
  resourceType,
  resourceId,
  normalization,
  method,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
HandlePatchRequestOptions): Promise<{ data: any } | { error: any }> {
  // Determine the correct serialMethod based on the HTTP method
  const serialMethod = method === 'PATCH' ? 'PATCH' : 'POST';

  // Create a new copy of the data object to avoid modifying the original data
  const neuData = method === 'POST' ? { ...data.body } : { ...data, id: resourceId };

  // Serialize the data object
  const serialData = serialise(resourceType, neuData, serialMethod);

  try {
    let responseData;

    switch (method) {
      case 'POST': {
        // Send a POST request to the API and extract the response data
        ({ data: responseData } = await jsonApiClient.post(url, serialData, {
          params,
          ...axiosOptions,
        }));

        break;
      }
      case 'PUT': {
        // Send a PUT request to the API and extract the response data
        ({ data: responseData } = await jsonApiClient.put(url, serialData, {
          params,
          ...axiosOptions,
        }));
        break;
      }
      case 'PATCH': {
        // Send a PATCH request to the API and extract the response data
        ({ data: responseData } = await jsonApiClient.patch(url, serialData, {
          params,
          ...axiosOptions,
        }));
        break;
      }
    }

    try {
      // Normalize the response data if the method was not a POST request
      if (method === 'POST') {
        // on create there is no need to normalise the resource because the creator will not know the id of the new resource
        return { data: deserialise(responseData) };
      } else {
        return normalizeResource(deserialise(responseData), normalization);
      }
    } catch (error) {
      // If there is an error normalizing the data, log a warning and return the deserialized data object
      console.warn('Error normalising resource', error);
      return { data: { ...deserialise(responseData) } };
    }
  } catch (error) {
    const err = error as ErrorResponseType;
    const status = err.response?.status;
    const data = err.response?.data?.errors ?? err.response?.data ?? err.message;
    return {
      error: {
        status,
        data,
      },
    };
  }
}
