import { enUS, esES } from '@mui/material/locale';
import * as dateFNSLocale from 'date-fns/locale';
import { PATH_DASHBOARD } from './routes/paths';

// WINDOW EXPORTS
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const {
  ENABLE_MANAGED_CERTIFICATES,
  API_URL: HOST_API,
  AUTH_CLIENT_ID,
  AUTH_DOMAIN,
  AUTH_AUDIENCE,
  UI_UNLEASH_CLIENT_KEY,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} = window as any;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.clusters; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 64,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 96 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 240,
  DASHBOARD_WIDTH: 250,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// Icon is driven by lipis/flag-icons
// icon prop is a class where fi-xx (where xx is the lowercased ISO 3166-1-alpha-2 code of a country) found here https://www.iso.org/obp/ui/#search/code/
export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    dateFNSLocale: dateFNSLocale.enUS,
    icon: 'US',
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: esES,
    dateFNSLocale: dateFNSLocale.es,
    icon: 'ES',
  },
];

export const defaultLang = allLangs[0]; // English

export const AUTH0_API = {
  clientId: AUTH_CLIENT_ID,
  domain: AUTH_DOMAIN,
  audience: AUTH_AUDIENCE,
};

export const UNLEASH_CONFIG = {
  url: 'https://us.app.unleash-hosted.com/usgg0012/api/frontend', // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: import.meta.env.VITE_UI_UNLEASH_CLIENT_KEY || UI_UNLEASH_CLIENT_KEY, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 120, // How often (in seconds) the client should poll the proxy for updates
  appName: 'conductor-ui', // The name of your application. It's only used for identifying your application
};
