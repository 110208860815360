import i18next from 'i18next';
import * as Yup from 'yup';

const ConductorResourceMetadata = Yup.object().shape({
  name: Yup.string()
    .trim()
    .lowercase()
    .test({
      name: 'name',
      message: 'Name is required',
      test: (value, ctx) => {
        if (value === undefined || value === null || value === '') {
          return ctx.createError({ message: i18next.t('validation.name.required', { ns: 'shared' }) });
        }

        // value can't contain spaces
        if (value?.includes(' ')) {
          return ctx.createError({ message: i18next.t('validation.name.noSpaces', { ns: 'shared' }) });
        }

        // value can't contain special characters
        if (value?.match(/[^a-zA-Z0-9-]/)) {
          return ctx.createError({ message: i18next.t('validation.name.onlyAlphaNumeric', { ns: 'shared' }) });
        }

        // must start and end with letter
        if (value?.match(/^[^a-zA-Z]/)) {
          return ctx.createError({ message: i18next.t('validation.name.startEndAlphaNumeric', { ns: 'shared' }) });
        }

        return true;
      },
    }),
});

export { ConductorResourceMetadata };
