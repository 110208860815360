import { Box, SxProps, Theme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import SimpleBarReact from 'simplebar-react';

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.5),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: '0.875rem',
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

type ScrollbarProps = {
  sx?: SxProps<Theme>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export function Scrollbar({ children, sx, ...other }: PropsWithChildren<ScrollbarProps>) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <SimpleBarStyle
        clickOnTrack={false}
        sx={{
          paddingRight: '1.25rem',
          ...sx,
        }}
        forceVisible="y"
        autoHide={false}
        {...other}
      >
        {children}
      </SimpleBarStyle>
    </Box>
  );
}
