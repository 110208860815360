import { OrganizationContext, PRODUCT_PLANS } from '@diagrid/cloud-ui-shared';
import { CollapseButton, Scrollbar } from '@diagrid/cloud-ui-shared/components';
import { useCollapseDrawer, useResponsive } from '@diagrid/cloud-ui-shared/hooks';
import { cssUtils } from '@diagrid/cloud-ui-shared/utils/theme';
import { GitHub, MenuBookSharp, QuestionAnswerSharp, StoreSharp, SupportAgentSharp, VideoLibrarySharp } from '@mui/icons-material';
import { Box, Divider, Drawer, List, ListSubheader, Stack, Typography, useTheme } from '@mui/material';
import { useUnleashClient } from '@unleash/proxy-client-react';
import { cloneDeep, filter, has } from 'lodash';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DiagridLogo } from 'src/components/DiagridLogo';
import { ProductSwitcher } from 'src/components/ProductSwitcher';
import { NavSectionVertical } from 'src/components/nav-section';
import { NavItemRoot } from 'src/components/nav-section/vertical/NavItem';
import { NAVBAR } from 'src/config';
import { UsersRolesContext } from 'src/contexts/UsersRolesContext';
import { useLocales } from 'src/hooks/useLocales';
import { LINKS } from 'src/utils/external-links';
import navConfig from './NavConfig';

type NavbarVerticalProps = {
  isOpenSidebar?: boolean;
  onCloseSidebar: () => void;
};

const {
  mcp: { free },
} = PRODUCT_PLANS;

export function NavbarVertical({ isOpenSidebar, onCloseSidebar }: NavbarVerticalProps) {
  const theme = useTheme();
  const { translate } = useLocales();
  const unleashClient = useUnleashClient();
  const { isAuthorized } = useContext(UsersRolesContext);
  const { planName } = useContext(OrganizationContext);

  const filteredNavConfig = filter(cloneDeep(navConfig), (item) => {
    if (item?.children) {
      item.children = filter(item.children, (child) => {
        if (has(child, 'featureFlag') && !unleashClient?.isEnabled(child.featureFlag)) {
          return false;
        }

        return !has(child, 'rbac') || isAuthorized(child.rbac);
      });
    }

    if (has(item, 'featureFlag') && !unleashClient?.isEnabled(item.featureFlag)) {
      return false;
    }

    return !has(item, 'rbac') || isAuthorized(item.rbac);
  });

  const isFreePlan = planName === free;

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center', paddingLeft: '25px', paddingRight: '15px' }),
        }}
      >
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
          <ProductSwitcher isCollapse={isCollapse} />

          {isDesktop && !isCollapse && <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
        </Stack>
      </Stack>

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <NavSectionVertical navConfig={filteredNavConfig} isCollapse={isCollapse} />

        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <List disablePadding sx={{ px: 2 }}>
            <ListSubheader
              disableSticky
              disableGutters
              sx={{
                ...theme.typography.overline,
                paddingTop: theme.spacing(3),
                paddingLeft: theme.spacing(2),
                paddingBottom: theme.spacing(1),
                color: theme.palette.text.primary,
                transition: theme.transitions.create('opacity', {
                  duration: theme.transitions.duration.shorter,
                }),

                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {translate('generalLabels.reference')}
            </ListSubheader>
            {isFreePlan && (
              <>
                <NavItemRoot
                  item={{
                    title: translate('generalLabels.documentation'),
                    path: LINKS.docs,
                    icon: <MenuBookSharp sx={{ width: 1, height: 1 }} />,
                  }}
                  active={false}
                  isCollapse={isCollapse}
                />
                <NavItemRoot
                  item={{
                    title: translate('generalLabels.videos'),
                    path: LINKS.videos,
                    icon: <VideoLibrarySharp sx={{ width: 1, height: 1 }} />,
                  }}
                  active={false}
                  isCollapse={isCollapse}
                />
                <NavItemRoot
                  item={{
                    title: translate('generalLabels.community'),
                    path: LINKS.community,
                    icon: <QuestionAnswerSharp sx={{ width: 1, height: 1 }} />,
                  }}
                  active={false}
                  isCollapse={isCollapse}
                />
              </>
            )}
            <NavItemRoot
              item={{
                title: isFreePlan ? translate('generalLabels.feedback') : translate('generalLabels.reportIssue'),
                path: LINKS.reportIssue,
                icon: <GitHub sx={{ width: 1, height: 1 }} />,
              }}
              active={false}
              isCollapse={isCollapse}
            />
            {!isFreePlan && (
              <>
                <NavItemRoot
                  item={{
                    title: translate('generalLabels.meetSales'),
                    path: LINKS.meet,
                    icon: <StoreSharp sx={{ width: 1, height: 1 }} />,
                  }}
                  active={false}
                  isCollapse={isCollapse}
                />
                <NavItemRoot
                  item={{
                    title: translate('generalLabels.meetSupport'),
                    path: LINKS.technicalSupport,
                    icon: <SupportAgentSharp sx={{ width: 1, height: 1 }} />,
                  }}
                  active={false}
                  isCollapse={isCollapse}
                />
              </>
            )}
          </List>
        </Box>
      </Scrollbar>
      <Stack
        sx={{
          pb: 2,
          textAlign: 'center',
          display: 'block',
        }}
      >
        <div>
          <Typography gutterBottom variant="caption">
            {translate('generalLabels.poweredBy')}
          </Typography>
          <DiagridLogo sx={{ maxHeight: '30px' }} />
        </div>
      </Stack>
    </>
  );

  const drawerStyles = {
    width: NAVBAR.DASHBOARD_WIDTH,
    borderRightStyle: 'dashed',
    bgcolor: 'background.default',
    transition: (theme) =>
      theme.transitions.create('width', {
        duration: theme.transitions.duration.standard,
      }),
    ...(isCollapse && {
      width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
    ...(collapseHover && {
      ...cssUtils(theme).bgBlur(),
      boxShadow: (theme) => theme.customShadows.z24,
    }),
  };

  return (
    <Box
      sx={{
        [theme.breakpoints.up('lg')]: {
          flexShrink: 0,
          transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.shorter,
          }),
        },
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: drawerStyles,
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
